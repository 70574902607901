import * as React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby"
import Seo from "../components/seo";
import { Col, Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";

const NotFoundPage = () => (
    <Layout>
        <Row className="content">
            <Col>
                <h1>Die Seite konnte leider nicht gefunden werden.</h1>
                <p>404 (NOT FOUND)</p>
                <Row>
                    <Col className="col-lg-2 offset-1">
                        <StaticImage src="../../static/img/comikgk-frame-2.png" alt="gastrokalk Chef" height={260} />
                    </Col>
                    <Col className="align-self-end col-lg-5">
                        <p>Bitte überprüfen Sie Ihre Eingabe oder besuchen Sie unsere</p>
                        <p style={{textAlign: "center"}}><strong><Link to="/">Gastrokalk Homepage</Link></strong></p>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-lg-3 offset-4 justify-content-center">
                        <StaticImage src="../../static/img/comikgk-frame-farbe-4p.png" alt="gastrokalk Chef" height={344} />
                    </Col>
                </Row>
            </Col>
        </Row>
    </Layout>
);

export default NotFoundPage;

export const Head = () => <Seo title="Nicht Gefunden" />
